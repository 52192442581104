<template>
    <div>
        <v-row>
            <v-col cols="12" v-if="$parent.$parent.get_permission('show_mails')">
                <DashCard title="Configuración general" title-icon="settings" body-class="pa-0" :actions="true" actions-align="right">
                    <v-tabs show-arrows>
                        <!-- inhabilitado debido a que la api de meteodata no se esta usando -->
                        <!-- <v-tab class="subtitle-1 font-weight-bold">MP10</v-tab> -->
                        <v-tab class="subtitle-1 font-weight-bold">SIAS</v-tab>
                        <v-tab class="subtitle-1 font-weight-bold">Incidentes</v-tab>
                        <v-tab class="subtitle-1 font-weight-bold">Compromisos seguridad</v-tab>
                        <v-tab class="subtitle-1 font-weight-bold">Tareas Críticas</v-tab>
                        <v-tab-item><Config1 :sets="notif_mp10" :estaciones="estaciones" /></v-tab-item>
                        <v-tab-item><Config2 :sets="notif_sias"  /></v-tab-item>
                        <v-tab-item><Config3 :sets="notif_incidentes" /></v-tab-item>
                        <v-tab-item><Config4 :sets="notif_lv" /></v-tab-item>
                        <v-tab-item><Config5 :sets="notif_permisos" /></v-tab-item>
                    </v-tabs>
                    <template slot="actions">
                        <v-btn type="submit" large depressed color="primary" @click="upload_changes" form="editor">
                            <span class="subtitle-2">Guardar cambios</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
        </v-row>
        <Message ref="dialog" />
        <LoadingOverlay v-model="loading" />
    </div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import Config1 from '@/dash/form_boxes/box_config_1'
import Config2 from '@/dash/form_boxes/box_config_2'
import Config3 from '@/dash/form_boxes/box_config_3'
import Config4 from '@/dash/form_boxes/box_config_4'
import Config5 from '@/dash/form_boxes/box_config_5'
import Message from '@/shared/components/Message'
import LoadingOverlay from '@/dash/components/LoadingOverlay'
import { axiosHelper, messages } from '@/helpers'

export default {
    components: {
        LoadingOverlay,
        Message,
        DashCard,
        Config1,
        Config2,
        Config3,
        Config4,
        Config5
    },
    data: () => ({
        conf: [],
        loading: true,
        estaciones: [],
        index: null
    }),
    computed: {
        notif_mp10() {
            return this.conf.filter(x => [0].includes(x.config_type))
        },
        notif_sias() {
            return this.conf.filter(x => [1].includes(x.config_type))
        },
        notif_incidentes() {
            return this.conf.filter(x => [2, 3].includes(x.config_type))
        },
        notif_lv() {
            return this.conf.filter(x => [4].includes(x.config_type))
        },
        notif_permisos() {
            return this.conf.filter(x => [5, 6, 7].includes(x.config_type))
        }
    },
    mounted() {
        axiosHelper.get('mailconf/')
            .then(response => {
                this.conf = response.data.settings;
                this.loading = false;
                this.estaciones = response.data.estaciones.filter(x => x.vars.includes('mp10'));
            })
            .catch(response => this.loading = false);
        this.$emit('title', { text: "Sistema de alertas por correo", icon: "email" });
    },
    methods: {
        upload_changes() {
            axiosHelper.put('mailconf/', this.conf)
                .then(response => this.success())
                .catch(error => this.error(error))
        },
        success() {
            this.$refs.dialog.show(messages.titles['success_generico'], messages.texts['success_cambios_alertas'], "success");
        },
        error(response) {
            let text = messages.texts['error_desconocido'];
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_generico'], "error");
        }
    }
}
</script>